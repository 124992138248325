import React from 'react'

import { SessionSummaryProps } from '@/offline/components/types/SessionTypes'
import { Icon } from '@/core/components/icons/Icon'

/**
 * Summary section
 * @param props summary text
 */
export function SessionSummary(props: SessionSummaryProps) {
  const { text } = props

  return (
    <summary
      className="list-none flex justify-between p-16 space-x-24 transition-colors border-b border-default -mb-1 z-10 active:!bg-gray-100 hover:bg-gray-50 group-open:active:!bg-blue-100 group-open:bg-blue-50"
      data-testid="sessions-summary"
    >
      <div className="flex items-center space-x-8 truncate">
        <div className="text-primary">
          <Icon className="group-open:hidden rect-24" icon="chevron_right" />
          <Icon
            className="hidden group-open:block rect-24"
            icon="chevron_down"
          />
        </div>
        <h2 className="font-semibold truncate" title={text}>
          {text}
        </h2>
      </div>
    </summary>
  )
}
