import React from 'react'

import { SessionProps } from '@/offline/components/types/SessionTypes'
import { Icon } from '@/core/components/icons/Icon'

/**
 * Session row place of service information
 * @param props session and styles elements
 */
export function PlaceOfServiceSession(props: SessionProps) {
  const { session, styleName } = props
  let street = null
  if (session.placeOfService) {
    if (session.placeOfService.street) {
      const query = session.placeOfService.street
        .concat(' ' + session.placeOfService.city)
        .concat(' ' + session.placeOfService.state)
        .concat(' ' + session.placeOfService.zipCode || '')
        .replace(/ /g, '+')
      street = (
        <a
          href={'https://www.google.com/maps/search/?api=1&query=' + query}
          target="_blank"
          rel="noreferrer"
          className={`text-sm underline text-link line-clamp-1 lg:line-clamp-2 ${styleName}`}
          title={
            session.placeOfService.street +
            '&#013;' +
            session.placeOfService.city +
            ', ' +
            session.placeOfService.state +
            ' ' +
            session.placeOfService.zipCode
          }
        >
          {session.placeOfService.street}
          <br /> {session.placeOfService.city}, {session.placeOfService.state}
          {session.placeOfService.zipCode}
        </a>
      )
    }
  }
  return (
    <>
      {session.placeOfService ? (
        <div className="inline-flex flex-col items-start w-full min-w-136">
          <p className={`flex items-center space-x-4 text-sm ${styleName}`}>
            <span>
              <Icon
                className="shrink-0 !rect-16 text-secondary-text rect-24"
                icon="location"
              />
            </span>
            <span>{session.placeOfService.posCodeTitle}</span>
          </p>
          {street}
        </div>
      ) : (
        <p className="text-sm text-secondary-text"></p>
      )}
    </>
  )
}
