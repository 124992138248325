import React from 'react'

import { SessionRow } from '@/offline/components/session/SessionRow'
import { SessionResultProps } from '@/offline/components/types/SessionTypes'

/**
 * Session table component
 * @param props session list
 */
export function SessionTable(props: SessionResultProps) {
  const { result } = props
  const headerStyles = 'px-8 py-12 border-b border-default'
  const sessionRows = result.map((session) => {
    return <SessionRow key={session.id} id={session.id} session={session} />
  })
  return (
    <div className="session-table w-full lg-d:max-h-[400px] lg-d:overflow-auto scrollbar-default scroll-shadow-horizontal relative">
      <table className="w-full border-separate" data-testid="clientSessions">
        <thead className="sticky top-0 text-xs font-semibold text-gray-600 uppercase border-b lg:top-navbar h-44 bg-gray-50 border-light">
          <tr>
            <th className={`pl-[60px] lg:pl-80 ${headerStyles}`}>Client</th>
            <th className={`${headerStyles}`}>Date and time</th>
            <th className={`${headerStyles}`}>Location</th>
            <th className={`${headerStyles}`}>Staff</th>
            <th className={`${headerStyles}`}>
              <p className="sr-only">Row action buttons</p>
            </th>
          </tr>
        </thead>
        {sessionRows}
      </table>
    </div>
  )
}
