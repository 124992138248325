import React from 'react'

import { SessionProps } from '@/offline/components/types/SessionTypes'

/**
 * Session row staff information
 * @param props session element
 */
export function StaffSession(props: SessionProps) {
  const { session } = props
  const avatarClass =
    'rounded-full bg-gray-200 flex items-center justify-center shrink-0 text-secondary-text rect-32 text-sm lg:rect-36 !no-underline'
  if (session.staff) {
    return (
      <div className={avatarClass} aria-hidden="true">
        <span>{session.staff.initials}</span>
      </div>
    )
  }
  return <></>
}
