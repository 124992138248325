import { OfflineManagerInterface } from '@/offline/interfaces/OfflineManagerInterface'
import { GatheredData, Stores, TableData } from '@/offline/models'
import { encryptValues } from '@/offline/services/DefaultCryptographyService'

import { OfflineApplicationInterface } from './interfaces/OfflineApplicationInterface'
import { BackgroundSyncManagerInterface } from './interfaces/BackgroundSyncManagerInterface'
import StorageMonitor from './managers/StorageMonitor'

export class OfflineApplication implements OfflineApplicationInterface {
  private readonly offlineManager: OfflineManagerInterface
  private readonly serverData: GatheredData
  private readonly storageMonitor: StorageMonitor
  private symmetricKey: CryptoKey
  private readonly backgroundSyncManager: BackgroundSyncManagerInterface
  static instance: OfflineApplication

  constructor(
    offlineManager: OfflineManagerInterface,
    backgroundSyncManager: BackgroundSyncManagerInterface,
  ) {
    this.offlineManager = offlineManager
    this.backgroundSyncManager = backgroundSyncManager
    this.storageMonitor = new StorageMonitor()
    this.symmetricKey = {} as CryptoKey
    this.serverData = []

    if (typeof OfflineApplication.instance === 'object') {
      return OfflineApplication.instance
    }
    OfflineApplication.instance = this
    return this
  }

  getOfflineManager(): OfflineManagerInterface {
    return this.offlineManager
  }

  getBackgroundSyncManager(): BackgroundSyncManagerInterface {
    return this.backgroundSyncManager
  }

  getStorageMonitor(): StorageMonitor {
    return this.storageMonitor
  }

  // This is the main entry point for the application
  async init(): Promise<boolean | undefined> {
    if (this.offlineManager.isBlocklisted()) {
      console.log('the url is blocklisted')
      return undefined
    }
    this.symmetricKey = await this.offlineManager.getSymmetricKey()

    return await this.offlineManager.initDB()
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('OfflineApplication instance not created yet!')
    }
    return this.instance
  }

  sendNotification(text: string, callback: () => void) {
    const notificationElement = document.getElementById('offline-notification')
    const notificationMessageElement = document.getElementById(
      'offline-notification-message',
    )
    if (notificationElement && notificationMessageElement) {
      notificationMessageElement.innerText = text
      notificationElement.classList.toggle('hidden')
      setTimeout(callback, 3000)
    }
  }

  async storeDataComingFromServer() {
    if (Array.isArray(this.serverData) && this.serverData.length) {
      this.offlineManager.storeData(this.serverData)
    }
  }

  async pushServerData(table: Stores, data: object[] | object) {
    Array.isArray(this.serverData) &&
      this.serverData.push({
        table: table,
        data: await encryptValues(data, this.symmetricKey),
      })
  }

  async storeData(table: Stores, data: TableData) {
    this.offlineManager.storeData({ table, data })
  }
}
