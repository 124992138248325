import React from 'react'

import { ClientSession } from '@/offline/components/session/ClientSession'
import { DateSession } from '@/offline/components/session/DateSession'
import { PlaceOfServiceSession } from '@/offline/components/session/PlaceOfServiceSession'
import { StaffSession } from '@/offline/components/session/StaffSession'
import { ActionsSession } from '@/offline/components/session/ActionsSession'
import { SessionProps } from '@/offline/components/types/SessionTypes'

/**
 * // TODO: Add state handling using IndexedDB as initial store
 * Creates Session Row html element reusing structure from Django template
 * @param props session and id elements
 * @returns session html element
 */
export function SessionRow(props: SessionProps) {
  const { session, id } = props
  let rowStyles = null
  let textStrikethroughStyles = null

  if (session.state == 'cancelled') {
    rowStyles = 'px-8 py-12 whitespace-nowrap'
    textStrikethroughStyles = 'line-through !text-secondary-text'
  } else {
    rowStyles = 'px-8 py-12 border-b border-light whitespace-nowrap'
    textStrikethroughStyles = ''
  }

  return (
    <tbody id={id}>
      <tr>
        <td className={rowStyles}>
          <ClientSession
            session={session}
            styleName={textStrikethroughStyles}
          />
        </td>
        <td className={`lg:space-y-4 ${rowStyles} ${textStrikethroughStyles}`}>
          <DateSession session={session} />
        </td>
        <td className={rowStyles}>
          <PlaceOfServiceSession
            session={session}
            styleName={textStrikethroughStyles}
          />
        </td>
        <td className={rowStyles}>
          <StaffSession session={session} />
        </td>
        <td className={`pl-24 pr-12 ${rowStyles}`}>
          <ActionsSession session={session} />
        </td>
      </tr>
    </tbody>
  )
}
