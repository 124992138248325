import React from 'react'

import { ClientTable } from '@/offline/components/client/ClientTable'
import { useOfflineClients } from '@/offline/hooks/useOfflineClient'

export const ClientList = () => {
  const { clients } = useOfflineClients()
  return (
    <>
      <div id="clients" className="w-full bg-white group">
        <ClientTable result={clients} />
      </div>
    </>
  )
}
