import { useEffect, useState } from 'react'

import { Indexes, Client, Stores } from '@/offline/models'
import { decryptValues } from '@/offline/services/DefaultCryptographyService'
import { getOfflineApp } from '@/offline/helpers'

export const useOfflineClients = () => {
  const [clients, setClients] = useState<Client[]>([])

  useEffect(() => {
    fillOfflineClients()
  }, [])

  const fillOfflineClients = async () => {
    const offlineApp = await getOfflineApp()
    if (!offlineApp) return
    const offlineManager = offlineApp.getOfflineManager()

    const dbOk = await offlineManager.getLocalDataManager().initDB()
    if (dbOk) {
      const clients: Client[] | null = await offlineManager
        .getLocalDataManager()
        .getDataByIndex(Stores.Client, Indexes.User, window.UUID)

      if (clients) {
        setClients(
          await decryptValues(clients, await offlineManager.getSymmetricKey()),
        )
      }
    }
  }

  return { clients }
}
