import { Stores } from '../models'
import { importCryptoKey } from '../services/DefaultCryptographyService'
import { LocalDataManagerInterface } from '../interfaces/LocalDataManagerInterface'
import { SymmetricKeyManagerInterface } from '../interfaces/SymmetricKeyManagerInterface'

export default class SymmetricKeyManager
  implements SymmetricKeyManagerInterface
{
  private localDataManager: LocalDataManagerInterface

  constructor(localDataManager: LocalDataManagerInterface) {
    this.localDataManager = localDataManager
  }

  async get() {
    await this.localDataManager.initDB()
    const symmetricKey = await this.localDataManager.getStoreData<{
      id: 1
      symmetric_key: CryptoKey
    }>(Stores.SymmetricKey)

    if (symmetricKey.length && symmetricKey[0]) {
      return symmetricKey[0].symmetric_key
    }

    return this.fetchAndCache()
  }

  async fetchAndCache() {
    const response = await fetch('/api/users/v1/user/symmetric-key', {
      credentials: 'same-origin',
    })
    const json = await response.json()

    if (!json.symmetric_key) {
      throw new Error('No symmetric key returned')
    }

    const key = await importCryptoKey(json.symmetric_key)

    await this.localDataManager.initDB()
    await this.localDataManager.addData(Stores.SymmetricKey, {
      id: 1, // always use 1 so we overwrite the old key when we get a new one
      symmetric_key: key,
    })

    return key
  }
}
