import { useEffect, useState } from 'react'

import { Indexes, Session, Stores } from '@/offline/models'
import { getBeginingOfDate } from '@helpers/utils'
import { decryptValues } from '@/offline/services/DefaultCryptographyService'
import { getOfflineApp } from '@/offline/helpers'

export const useOfflineSessions = () => {
  const [upcomingResults, setUpcomingResults] = useState<Session[]>([])
  const [pastResults, setPastResults] = useState<Session[]>([])

  useEffect(() => {
    fillOfflineSessions()
  }, [])

  const fillOfflineSessions = async () => {
    const offlineApp = await getOfflineApp()
    if (!offlineApp) return
    const offlineManager = offlineApp.getOfflineManager()

    const dbOk = await offlineManager.getLocalDataManager().initDB()
    if (dbOk) {
      let sessions: Session[] | null = await offlineManager
        .getLocalDataManager()
        .getDataByIndex(Stores.Session, Indexes.User, window.UUID)
      sessions = await decryptValues(
        sessions,
        await offlineManager.getSymmetricKey(),
      )

      if (sessions) {
        const beginningOfToday = getBeginingOfDate()
        const { upcomingSessions, pastSessions } = sessions.reduce(
          (
            acc: { upcomingSessions: Session[]; pastSessions: Session[] },
            session: Session,
          ) => {
            if (new Date(session.scheduledStartAt) >= beginningOfToday) {
              acc.upcomingSessions.push(session)
            } else {
              acc.pastSessions.push(session)
            }
            return acc
          },
          { upcomingSessions: [], pastSessions: [] },
        )
        setUpcomingResults(upcomingSessions)
        setPastResults(pastSessions)
      }
    }
  }

  return { upcomingResults, pastResults }
}
