import { GatheredData } from '@/offline/models'
import { config } from '@/offline/config'
import { decryptValues } from '@/offline/services/DefaultCryptographyService'
import { growthbook } from '@/core/services/growthbook'

import { LocalDataManagerInterface } from '../interfaces/LocalDataManagerInterface'
import { OfflineManagerInterface } from '../interfaces/OfflineManagerInterface'
import { SymmetricKeyManagerInterface } from '../interfaces/SymmetricKeyManagerInterface'

export class DefaultOfflineManager implements OfflineManagerInterface {
  private localDataManager: LocalDataManagerInterface
  private symmetricKeyManager: SymmetricKeyManagerInterface

  constructor(
    symmetricKeyManager: SymmetricKeyManagerInterface,
    localDataManager: LocalDataManagerInterface,
  ) {
    this.localDataManager = localDataManager
    this.symmetricKeyManager = symmetricKeyManager
  }

  getLocalDataManager(): LocalDataManagerInterface {
    return this.localDataManager
  }

  async initDB() {
    const dbOk = await this.localDataManager.initDB()
    return !!dbOk
  }

  async getSymmetricKey(): Promise<CryptoKey> {
    return this.symmetricKeyManager.get()
  }

  public addOnlineStatusListener(callback: (online: boolean) => void): void {
    window.addEventListener('online', () => callback(true))
    window.addEventListener('offline', () => callback(false))
  }

  isOfflineModeEnabled(): boolean {
    return growthbook.getFeatureValue('paw_patrol_offline_mode', false)
  }

  //validates if the route is blacklisted
  isBlocklisted(): boolean {
    const { URL_BLOCKLIST } = config
    return URL_BLOCKLIST.some((url) => window.location.pathname.startsWith(url))
  }

  storeData(gatheredData: GatheredData) {
    if (!Array.isArray(gatheredData)) gatheredData = [gatheredData]
    gatheredData.forEach(async ({ table, data }) => {
      await this.localDataManager.addData(table, data)
    })
  }

  async getStoreData(store: string, index: string, value: string) {
    const data = await this.getLocalDataManager().getDataByIndex(
      store,
      index,
      value,
    )
    return await decryptValues(data, await this.getSymmetricKey())
  }

  sendNotification(text: string, callback: () => void) {
    const notificationElement = document.getElementById('offline-notification')
    const notificationMessageElement = document.getElementById(
      'offline-notification-message',
    )
    if (notificationElement && notificationMessageElement) {
      notificationMessageElement.innerText = text
      notificationElement.classList.toggle('hidden')
      setTimeout(callback, 3000)
    }
  }
}
