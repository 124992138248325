import React from 'react'

import { SessionProps } from '@/offline/components/types/SessionTypes'

/**
 * Session row client information
 * @param props session and styles elements
 */
export function ClientSession(props: SessionProps) {
  const { session, styleName } = props
  const avatarClass =
    'rounded-full bg-gray-200 flex items-center justify-center shrink-0 text-secondary-text lg:rect-56 rect-36'
  return (
    <div className="flex items-center space-x-16">
      <div className={avatarClass} aria-hidden="true">
        <span>{session.treatmentPlan.client.initials}</span>
      </div>
      <div className={`lg:space-y-4 ${styleName}`}>
        <p className="text-sm font-semibold">
          {session.treatmentPlan.client.shortFullName}
        </p>
        <p className="text-sm text-secondary-text">
          ID: {session.treatmentPlan.client.mrn}
        </p>
      </div>
    </div>
  )
}
