import React from 'react'
import { createRoot } from 'react-dom/client'

import { SessionList } from '@/offline/components/session/SessionList'
import { ClientList } from '@/offline/components/client/ClientList'
import { Logout } from '@/offline/components/Logout'

const path = location.pathname

let root
let Component

if (path === '/offline/sessions/') {
  root = document.getElementById('offline-sessions')
  Component = <SessionList />
}
if (path === '/offline/clients/') {
  root = document.getElementById('offline-clients')
  Component = <ClientList />
}
if (path === '/offline/accounts/logout/') {
  root = document.getElementById('offline-logout')
  Component = <Logout />
}

// Attach React
if (root) {
  root = createRoot(root)

  root.render(<React.StrictMode>{Component}</React.StrictMode>)
}
