import React from 'react'
import { useTranslation } from 'react-i18next'

import { SessionSummary } from '@/offline/components/session/SessionSummary'
import { SessionTable } from '@/offline/components/session/SessionTable'
import { useOfflineSessions } from '@/offline/hooks/useOfflineSession'

/**
 * Session List Root component
 * @param props upcoming and past session list information
 */
export const SessionList = () => {
  const { upcomingResults, pastResults } = useOfflineSessions()

  const { t } = useTranslation(['offline'])

  return (
    <>
      <details id="sessions" className="w-full bg-white group" open>
        <SessionSummary text={t('Upcoming Sessions', { ns: 'offline' })} />
        <SessionTable result={upcomingResults} />
      </details>
      <details id="sessions" className="w-full bg-white group">
        <SessionSummary text={t('Past Sessions', { ns: 'offline' })} />
        <SessionTable result={pastResults} />
      </details>
    </>
  )
}
