import React from 'react'

import { SessionProps } from '@/offline/components/types/SessionTypes'

/**
 * Session row action buttons
 * @param props session object
 */
export function ActionsSession(props: SessionProps) {
  const { session } = props
  let action = null
  if (session.state == 'pending') {
    action = (
      <button
        hx-post={'/sessions/' + session.id + '/start'}
        className="btn-sm btn-primary shrink-0"
        data-testid="sessionIdentityButton"
      >
        Join
      </button>
    )
  } else if (session.state == 'active') {
    action = (
      <a
        href={'/sessions/' + session.id + '/join'}
        className="btn-sm btn-primary shrink-0"
        data-testid="sessionIdentityButton"
      >
        Join
      </a>
    )
  } else if (
    session.state == 'completed' &&
    (session.soapNoteStatus == 'Not started' ||
      session.soapNoteStatus == 'Generated')
  ) {
    action = (
      <a
        className="rounded-full btn btn-sm shrink-0"
        href={'/sessions/' + session.id + '/post-session'}
      >
        Wrap up
      </a>
    )
  } else if (
    session.state == 'completed' &&
    session.soapNoteStatus == 'Signed'
  ) {
    action = (
      <a
        className="btn btn-sm shrink-0"
        href={'/sessions/' + session.id + '/post-session'}
      >
        View
      </a>
    )
  }
  return <div className="flex items-center justify-end space-x-8">{action}</div>
}
