import React from 'react'

import {
  DateFormatTypes,
  DateFormatProps,
} from '@/offline/components/types/SessionTypes'
import { Icon } from '@/core/components/icons/Icon'

/**
 * DateFormat component according to formatType
 * @param props session, parameters and formatType elements
 */
export function DateFormat(props: DateFormatProps) {
  const { dates, parameters, formatType } = props
  const language = navigator.language
  const classDates = 'flex items-center space-x-8'
  const classIcon = '!rect-16 rect-24'
  const iconName = formatType == DateFormatTypes.Date ? 'calendar' : 'time'
  const dateFormat = dates.map((date, index, array) => {
    const item =
      formatType == DateFormatTypes.Date
        ? new Date(date).toLocaleDateString(language, parameters)
        : new Date(date).toLocaleTimeString(language, parameters)
    const nextItem = array[index + 1]
    return nextItem ? (
      <span key={index}>{item} - </span>
    ) : (
      <span key={index}>{item}</span>
    )
  })
  return (
    <div className={classDates}>
      <Icon className={classIcon} icon={iconName} />
      <p className="text-sm">{dateFormat}</p>
    </div>
  )
}
