import { Client, Session } from '@/offline/models'

export type SessionProps = {
  session: Session
  styleName?: string
  id?: string
}

export type ClientProps = {
  client: Client
  styleName?: string
  id?: string
}

export type SessionResultProps = {
  result: Session[]
}

export type ClientResultProps = {
  result: Client[]
}

export type SessionSummaryProps = {
  text: string
}

export type SessionListrops = {
  upcomingResults: Session[]
  pastResults: Session[]
}

export type DateFormatProps = {
  dates: string[]
  parameters: Intl.DateTimeFormatOptions
  formatType: DateFormatTypes
}

export enum DateFormatTypes {
  Date,
  DateTime,
}
