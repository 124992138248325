import React from 'react'

import { DateFormat } from '@/offline/components/utils/DateFormat'
import {
  DateFormatTypes,
  SessionProps,
} from '@/offline/components/types/SessionTypes'

/**
 * Session row date information
 * @param props session element
 */
export function DateSession(props: SessionProps) {
  const { session } = props
  return (
    <>
      <DateFormat
        dates={[session.scheduledStartAt]}
        parameters={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }}
        formatType={DateFormatTypes.Date}
      />
      <DateFormat
        dates={[session.scheduledStartAt, session.scheduledEndAt]}
        parameters={{
          hour: '2-digit',
          minute: '2-digit',
        }}
        formatType={DateFormatTypes.DateTime}
      />
    </>
  )
}
