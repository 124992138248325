import React from 'react'

import { ClientProps } from '@/offline/components/types/SessionTypes'

export const ClientRow = (props: ClientProps) => {
  const { client, id } = props

  return (
    <tbody id={id}>
      <tr>
        <td className="px-8 py-12 whitespace-nowrap">
          {client.firstName} {client.middleName} {client.lastName}
        </td>
        <td>{client.dob}</td>
      </tr>
    </tbody>
  )
}
