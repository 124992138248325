export enum SYNC_EVENT_TYPES {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  START = 'START',

  // Triggered by the user to indicate the system should attempt a sync now
  MANUAL = 'MANUAL',

  // Triggered by the HeartbeatManager to indicate the client has disconnected or reconnected to the network
  RECONNECT = 'RECONNECT',
  DISCONNECT = 'DISCONNECT',
}

export interface SyncEventBusManagerInterface {
  dispatch(e: Event): void
  addEventListener(
    type: keyof typeof SYNC_EVENT_TYPES,
    callback: () => void,
  ): void
}
