import React from 'react'
import { useTranslation } from 'react-i18next'

import { ClientRow } from '@/offline/components/client/ClientRow'
import { ClientResultProps } from '@/offline/components/types/SessionTypes'

export const ClientTable = (props: ClientResultProps) => {
  const { t } = useTranslation(['common'])
  const { result } = props

  return (
    <div className="session-table w-full lg-d:max-h-[400px] lg-d:overflow-auto scrollbar-default scroll-shadow-horizontal relative">
      <table className="w-full border-separate" data-testid="clientSessions">
        <thead className="sticky top-0 text-xs font-semibold text-gray-600 uppercase border-b lg:top-navbar h-44 bg-gray-50 border-light">
          <tr>
            <th className="pl-[60px] lg:pl-80 px-8 py-12 border-b border-default">
              {t('Client')}
            </th>
            <th className="px-8 py-12 border-b border-default">
              {t('Date of Birth')}
            </th>
          </tr>
        </thead>
        {result.map((client) => {
          return <ClientRow key={client.id} id={client.id} client={client} />
        })}
      </table>
    </div>
  )
}
